import { createSlice } from "@reduxjs/toolkit";

type setting ={
  isAuthenticated: boolean;
  isMenuExpanded: boolean;
  isUserLogged: boolean;
} 

const initialState : setting = {
  isAuthenticated: false,
  isMenuExpanded: true,
  isUserLogged: false,
};

const settingSlice = createSlice({
  name: "settingSlice",
  initialState,
  reducers: {
    updateAuth: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    toggleMenu: (state, action) => {
      state.isMenuExpanded = !state.isMenuExpanded;
    },
    toggleMenuOff: (state, action) => {
      state.isMenuExpanded = false;
    },
    updateLoggedStatus: (state, { payload }) => {
      state.isUserLogged = true;
    },
  },
});

export const { updateAuth, toggleMenu, toggleMenuOff, updateLoggedStatus } = settingSlice.actions;

export default settingSlice.reducer;
