import React from "react";
import { useLoader } from "../Context/LoaderContext";
import { Backdrop, CircularProgress } from "@mui/material";

const LoaderComponent: React.FC = () => {
  const { showLoader } = useLoader();
  return (
    <Backdrop className="loader" open={showLoader}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoaderComponent;
