import { withAuthenticationRequired } from "@auth0/auth0-react";

// export default ProtectedRoutes;
const ProtectedRoutes = ({ component, ...args }: { [x: string]: any; component: any }) => {
  const Component = withAuthenticationRequired(component, args);

  return <Component />;
};

export default ProtectedRoutes;
