import * as CryptoJS from 'crypto-js';
import cryptoRandomString from 'crypto-random-string';

export function doCryptoEncrypt(requestObj: any) {
	let encryptedStr = CryptoJS.HmacSHA256(JSON.stringify(requestObj), process.env.REACT_APP_CRYPTO_KEY!).toString(CryptoJS.enc.Base64);

	return encryptedStr;
}

export function setDataList (key: string, dataList: any) {
	return localStorage.setItem(
		key,
		CryptoJS.AES.encrypt(JSON.stringify(dataList), process.env.REACT_APP_CRYPTO_KEY!).toString()
	);
}

export function getDataList (key: string) {
	if (localStorage.getItem(key)) {
		return JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem(key)!, process.env.REACT_APP_CRYPTO_KEY!).toString(CryptoJS.enc.Utf8));
	}

	return null;
}

export function setDataVal (key: string, info: string) {
	localStorage.setItem(key, CryptoJS.AES.encrypt(JSON.stringify(info), process.env.REACT_APP_CRYPTO_KEY!).toString());
}

export function getDataVal (key: string) {
	if (localStorage.getItem(key)) {
		return CryptoJS.AES.decrypt(localStorage.getItem(key)!, process.env.REACT_APP_CRYPTO_KEY!).toString(CryptoJS.enc.Utf8);
	}

	return null;
}

export function getStringToVerifyHmac(queryString: any) {
	queryString.delete("hmac");
	queryString.sort()

	return queryString.toString();
}

export function convertToHmacStr (hmacStr: string) {
	return CryptoJS.HmacSHA256(hmacStr, process.env.REACT_APP_SHOPIFY_CLIENT_SECRET!).toString(CryptoJS.enc.Hex);
}

export function generateRandomStr(count: number) {
	return cryptoRandomString({length: count});
}

export function clearLocalStorage() {
	localStorage.clear();
}

export function goToRegisterPage() {
	let amlUrl = '';
	amlUrl    += process.env.REACT_APP_AML_URL + "register?";
	amlUrl    += "app=" + process.env.REACT_APP_AUTH0_APP_NAME;
	amlUrl    += "&callbackUrl=" + process.env.REACT_APP_BASE_URL + '?register=done';

	window.location.replace(amlUrl);
}
