import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GET_EBAY_TOKEN,
  GET_SHOPIFY_TOKEN,
  USER_REGISTER_URL,
  INITIATE_STORE_SETUP,
  CARRIER_CONNECT,
} from "../../Utils/Constants";
import AxiosInstance from "../../Network/AxiosInstance";
import { AxiosError, AxiosResponse } from "axios";

export const getAccessTokenApi = createAsyncThunk(
	GET_SHOPIFY_TOKEN,
	async (payload: any, thunkAPI) => {
	    try {
			let headerObj = {
				headers: {},
			};

			const response: AxiosResponse = await AxiosInstance.get(GET_SHOPIFY_TOKEN + payload.oAuthString, headerObj);
			return response.data;
		} catch (e) {
			const error = e as AxiosError;
			return thunkAPI.rejectWithValue({
				status: error.response?.status,
				statusText: error.response?.statusText,
				message: error.response?.data,
			});
		}
	}
);

export const registerUserApi = createAsyncThunk(
	USER_REGISTER_URL,
	async (payload: any, thunkAPI) => {
	    try {
			const response: AxiosResponse = await AxiosInstance.post(
				USER_REGISTER_URL,
				payload
			);

			return response.data;
		} catch (e) {
			const error = e as AxiosError;
			return thunkAPI.rejectWithValue({
				status: error.response?.status,
				statusText: error.response?.statusText,
				message: error.response?.data,
			});
		}
	}
);

export const initiateOmsCalls = createAsyncThunk(
	INITIATE_STORE_SETUP,
	async (payload: any, thunkAPI) => {
	    try {
			const response: AxiosResponse = await AxiosInstance.post(
				INITIATE_STORE_SETUP,
				payload
			);

			return response.data;
		} catch (e) {
			const error = e as AxiosError;
			return thunkAPI.rejectWithValue({
				status: error.response?.status,
				statusText: error.response?.statusText,
				message: error.response?.data,
			});
		}
	}
);

export const getEbayTokenApi = createAsyncThunk(
	GET_EBAY_TOKEN,
	async (payload: any, thunkAPI) => {
	    try {
			let headerObj = {
				headers: {},
			};

			const response: AxiosResponse = await AxiosInstance.get(GET_EBAY_TOKEN + payload.oAuthString, headerObj);

			return response.data;
		} catch (e) {
			const error = e as AxiosError;
			return thunkAPI.rejectWithValue({
				status: error.response?.status,
				statusText: error.response?.statusText,
				message: error.response?.data,
			});
		}
	}
);

export const connectCarrierApi = createAsyncThunk(
	CARRIER_CONNECT,
	async (payload: any, thunkAPI) => {
		try {
			const response: AxiosResponse = await AxiosInstance.post(CARRIER_CONNECT, payload);

			return response.data;
		} catch (e) {
			const error = e as AxiosError;
			return thunkAPI.rejectWithValue({
				status: error.response?.status,
				statusText: error.response?.statusText,
				message: error.response?.data,
			});
		}
	}
);

type user = {
	id: string;
	name: string;
	email: string;
	nickname: string;
};

const initialState: user = {
	id: "",
	name: "",
	email: "",
	nickname: "",
};
//current user
const userData: any = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateUserData: (state, { payload }) => {
		console.log(payload);
		state.id = payload.id;
		state.name = payload.name;
		state.email = payload.email;
		state.nickname = payload.nickname;
    },
    updateProfileDetails: (state, action) => {
      //   console.log(action.payload);
      // state[action.payload.name] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // omit posts loading reducers
    builder

      .addCase(getAccessTokenApi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("logoutUserApi.pending", action)
      })
      .addCase(getAccessTokenApi.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
        }
        // console.log("logoutUserApi.fulfilled", action)
      })
      .addCase(getAccessTokenApi.rejected, (state, action) => {
        // console.log("logoutUserApi.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      })
  },
});

export const { updateUserData, updateProfileDetails } = userData.actions;

export default userData.reducer;
