import { InputLabel, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";

const ErrorPage = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const error = (searchParams.has("error") ? searchParams.get("error") : 'Invalid Request');
	const errorDescription = (searchParams.has("error_description") ? searchParams.get("error_description") : 'Something Went Wrong');

	return (
		<div>
			<div className="mainDiv error-page-height dflex">
				<div className="error-details-container">
					<div className="dFlexJustifyCenter">
						<DoDisturbOnIcon color="error" />
						<Typography variant="h5" className="marginLeft10"> Access Denied </Typography>
					</div>
					<InputLabel className="marginTop30">
						<span className="fontWeight">Error:</span> <span>{error}</span>
					</InputLabel>
					<InputLabel className="marginTop30">
						<span className="fontWeight">Description:</span> <span>{errorDescription}</span>
					</InputLabel>
				</div>
			</div>
		</div>
	);
};

export default ErrorPage;
