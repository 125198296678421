import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {};

const masterData = createSlice({
	name: "masterData",
	initialState,
	reducers: {}
});

export default masterData.reducer;
