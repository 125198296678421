import React, { useEffect, useState } from "react";
import "./Assets/css/common.css";
import MainRoutes from "./Routes/MainRoutes";
import LoaderComponent from "./Components/LoaderComponent";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { LoaderContextProvider } from "./Context/LoaderContext";
import darkTheme from "./Theme/darkTheme";
import lightTheme from "./Theme/lightTheme";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "./Redux/app/hooks";
import { updateLoggedStatus } from "./Redux/features/settingSlice";
import { useSearchParams } from "react-router-dom";
import { setDataVal } from './Config/global';

const Home: React.FC = () => {
  const selectedTheme = { darkMode: false }; //useAppSelector((state) => state.theme);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isAccessToken, setIsAccessToken] = useState(false);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
	if (searchParams.has("type") && (searchParams.get('type') === 'usps')) {
		setDataVal('carrierConnected', searchParams.toString());
	}

    if (isAuthenticated) {
      const getToken = async () => {
        const accessToken = await getAccessTokenSilently();
        // console.log("accessToken", accessToken);
        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          setIsAccessToken(true);

          dispatch(updateLoggedStatus({}));
        }
      };

      getToken();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <div className="App">
      <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
        <LoaderContextProvider>
          <CssVarsProvider
            theme={selectedTheme.darkMode ? darkTheme : lightTheme}
          >
            <CssBaseline />

            <MainRoutes />
          </CssVarsProvider>
          <LoaderComponent />
        </LoaderContextProvider>
      </SnackbarProvider>
    </div>
  );
};

export default Home;
