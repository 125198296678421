import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

function BlankPage() {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
  	if (isAuthenticated) {
  		navigate('/loggedin');
  	}

    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <div className="">
      <div className="mainDiv">
        <div className="titleContainer">
          <div className="left"></div>
          <div className="right"></div>
        </div>

        <div className=""></div>
      </div>
    </div>
  );
}

export default BlankPage;
