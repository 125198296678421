import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import SuspenseContainer from "../Components/SuspenseContainer";
import ErrorPage from "../Pages/PublicRoutes/ErrorPage";
import { useAppSelector } from "../Redux/app/hooks";
import ProtectedRoutes from "./ProtectedRoutes";
import BlankPage from "../Pages/BlankPage";

const NotFound          = lazy(() => import("../Pages/PublicRoutes/NotFound"));
const Landing           = lazy(() => import("../Pages/Landing"));
const ShopifyOauth      = lazy(() => import("../Pages/Shopify/Oauth"));
const ShopifyAuthorized = lazy(() => import("../Pages/Shopify/Authorized"));

const ShopifyToSaleswrp = lazy(() => import("../Pages/Shopify/ToSaleswarp"));
const SaleswrpToShopify = lazy(() => import("../Pages/Shopify/FromSaleswarp"));

const EbayOauthCallback = lazy(() => import("../Pages/eBay/Oauth"));

const UserLogged        = lazy(() => import("../Pages/LoggedIn"));
const StoreConnected    = lazy(() => import("../Pages/Store/Connected"));
const ProductSubscribed = lazy(() => import("../Pages/Product/Subscribed"));

const CarrierConnected = lazy(() => import("../Pages/Carrier/Connected"));

const MainRoutes = React.memo(() => {
	const settingInfo = useAppSelector((state) => state.reducer.setting);
	// Check for query parameters and navigate to ErrorPage
	const searchParams = new URLSearchParams(window.location.search);
	const error = searchParams.get("error");
	if (error) {
		return (
			<Routes>
				<Route path="/error" element={<ErrorPage />} />
			</Routes>
		);
	}

	return (
		<Routes>
		    {/* below condition and route is added, because of not found route is displayed after login for fraction of second */}
	        {!settingInfo.isUserLogged && (
	          <>
	            <Route path="/store/connected" element={<StoreConnected />} />
	            <Route path="/ebay/callback" element={<EbayOauthCallback />} />

	            <Route
	              path="*"
                  element={
		            <SuspenseContainer>
		              <ProtectedRoutes component={BlankPage} />
		            </SuspenseContainer>
		          }
	            />
	          </>
	        )}

            {settingInfo.isUserLogged && (
             <>
				<Route
				  path="/loggedin"
				  element={
				     <SuspenseContainer>
	                  <ProtectedRoutes component={UserLogged} />
	                </SuspenseContainer>
				  }
				/>

				<Route
				  path="/product/subscribed"
				  element={
				     <SuspenseContainer>
	                  <ProtectedRoutes component={ProductSubscribed} />
	                </SuspenseContainer>
				  }
				/>

				<Route path="/shopify/oauth" element={<ShopifyOauth />} />
				<Route path="/shopify/authorized" element={<ShopifyAuthorized />} />

				<Route path="/shopify/fromSaleswarp" element={<SaleswrpToShopify />} />
				<Route path="/shopify/toSaleswarp" element={<ShopifyToSaleswrp />} />

				<Route path="/store/connected" element={<StoreConnected />} />
   				<Route path="/ebay/callback" element={<EbayOauthCallback />} />
				<Route path="/carrier/connected" element={<CarrierConnected />} />

				<Route path="/" element={<Landing />} />
		    </>
            )}
		</Routes>
	);
});

export default MainRoutes;
