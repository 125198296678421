import axios from "axios";
import store, { persistor } from "../Redux/app/store";

// const API_URL =
//   process.env.NODE_ENV === "development"
//     ? "https://6f67-122-179-159-122.in.ngrok.io/"
//     : "/admin-app/admin/api";

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=utf-8",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Origin": "*",
  },
});

AxiosInstance.interceptors.request.use(
  (config) => {
    // console.log("interceptor..........")
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // console.log("accessToken..........",accessToken)
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      // config.headers.common = { Authorization: `Bearer ${accessToken}` };
    }
    // console.log(config)
    return config;
  },
  (error) => {
    Promise.reject(error.response || error.message);
  }
);

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 504) {
      localStorage.removeItem("accessToken");
      //resetting store to initial state
      store.dispatch({ type: "RESET" });
      //resetting persistant storage
      persistor
        .purge()
        .then(() => {
          persistor.persist();
        })
        .catch((error) => console.error(error));
    }
    // console.log(error)
    // let originalRequest = error.config;
    // let refreshToken = localStorage.getItem("refreshToken");
    // const username = EmailDecoder(); // decode email from jwt token subject
    // if (
    //     refreshToken &&
    //     error.response.status === 403 &&
    //     !originalRequest._retry &&
    //     username
    // ) {
    //     originalRequest._retry = true;
    //     return axios
    //         .post(`${API_URL}/authentication/refresh`, {
    //             refreshToken: refreshToken,
    //             username,
    //         })
    //         .then(res => {
    //             if (res.status === 200) {
    //                 localStorage.setItem(
    //                     'accessToken',
    //                     res.data.accessToken
    //                 );
    //                 localStorage.setItem(
    //                     'refreshToken',
    //                     res.data.refreshToken
    //                 );

    //                 originalRequest.headers[
    //                     'Authorization'
    //                 ] = `Bearer ${res.data.accessToken}`;

    //                 return axios(originalRequest);
    //             }
    //         })
    //         .catch(() => {
    //             localStorage.clear();
    //             location.reload();
    //         });
    // }
    return Promise.reject(error.response || error.message);
  }
);

export default AxiosInstance;
